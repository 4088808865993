// Import the functions you need from the SDKs you need
import axios from 'axios';
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";


//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// const firebaseConfig = {
//     apiKey: process.env.apiKey,
//     authDomain: process.env.authDomain,
//     databaseURL: process.env.databaseURL,
//     projectId: process.env.projectId,
//     storageBucket: process.env.storageBucket,
//     messagingSenderId: process.env.messagingSenderId,
//     appId: process.env.appId,
//     measurementId: process.env.measurementId
// };


// const firebaseConfig = {
//     apiKey: "AIzaSyDhVCOuSWs7Aeyre1rux_AZVc4APgb7QG4",
//     authDomain: "apptesting-3dac2.firebaseapp.com",
//     databaseURL: "https://apptesting-3dac2.firebaseio.com",
//     projectId: "apptesting-3dac2",
//     storageBucket: "apptesting-3dac2.appspot.com",
//     messagingSenderId: "765651729938",
//     appId: "1:765651729938:web:865649aef98d170f61a2cb",
//     measurementId: "G-BMWJLR00KD"
// };
//const BASE_URL = "https://us-central1-delviewsofficial.cloudfunctions.net/api"

const fetchFirebaseConfig = async () => {

    try {
        const res = await axios.get("https://us-central1-delviewsofficial.cloudfunctions.net/api/firebase-config");
        console.log("Fire:", res);
        if (res.data) {
            return res.data;
        } else {
            console.error("Error fetching firebase config:", res.statusText);
            return null;
        }
    } catch (error) {
        console.error("Error fetching firebase config:", error);
        return null;
    }
}

const initializeFirebase = async () => {
    const firebaseConfig = await fetchFirebaseConfig();

    if (firebaseConfig) {
        const app = initializeApp({
            apiKey: firebaseConfig.apiKey,
            authDomain: firebaseConfig.authDomain,
            databaseURL: firebaseConfig.databaseURL,
            projectId: firebaseConfig.projectId,
            storageBucket: firebaseConfig.storageBucket,
            messagingSenderId: firebaseConfig.messagingSenderId,
            appId: firebaseConfig.appId,
        });
        const storage = getStorage(app);

        return { app, storage };
    } else {
        return null;
    }
};



// Initialize Firebase
//const app = initializeApp(firebase_config);
//const analytics = getAnalytics(app);


export default initializeFirebase;

