import axios from 'axios';

const BASE_URL = "https://us-central1-delviewsofficial.cloudfunctions.net/api"

// const API = axios.create({ baseURL: process.env.BASE_URL2 });

const API = axios.create({ baseURL: BASE_URL });

export const logIn = (formData) => API.post('/auth/login', formData);

export const signUp = (formData) => API.post('/auth/register', formData);
